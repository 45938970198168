/**
 * Functions
 */

function async(url, data, callback) {
  if (url) {

    $.post(url, data).done(function(payload) {
      if (typeof callback === 'function') {
        callback(payload);
      }

      if (payload.hasOwnProperty('location')) {
        window.location = payload['location'];
      }

    }).fail(function(e) {
      console.log('Error calling ' + url);
      console.log(e);
    });

    return true;
  }
  else {
    console.log('No url provided.');
    return false;
  }
}

function updateClock(clock) {
  var date = new Date();
  var minutes = date.getMinutes();

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  clock.html('<strong>' + date.getHours() + ':' + minutes +
      '</strong>');
}

function initDataTable(target, options) {
  var data = {
    destroy: true,
    language: {
      processing: 'Načítám...',
      lengthMenu: 'Zobraz záznamů _MENU_',
      zeroRecords: 'Žádné záznamy',
      info: 'Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů',
      infoEmpty: '',
      infoFiltered: '',
      infoPostFix: '',
      search: 'Hledat:',
      url: '',
      paginate: {
        first: 'První',
        previous: 'Předchozí',
        next: 'Další',
        last: 'Poslední',
      },
    },
  };

  $.extend(data, options);
  $(target).DataTable(data);
}

/**
 * Events
 */

$(document).on('click', '.confirm', function() {
  if (confirm('Opravdu chcete provést tuto akci?')) {
    return true;
  }
  else {
    $(this).blur();
    return false;
  }
});

$(function() {

  // apply datatables
  var datatables = $('#visit, #passage');
  datatables.each(function() {
    if ($(this).length > 0) {
      initDataTable('#' + $(this).attr('id'));
    }
  });

  // apply date picker
  var dates = $('#picker-from, #picker-to');
  dates.each(function() {
    if ($(this).length > 0) {
      $(this).datetimepicker({
        locale: 'cs',
        format: 'DD/MM/YYYY',
        icons: {
          next: 'icmn icmn-circle-right',
          previous: 'icmn icmn-circle-left',
        },
      });
    }
  });

  // apply tokenizer
  $('.tokenize').select2();

  // custom options
  var select_cars = $('#resident_cars');
  if (select_cars.length > 0) {
    select_cars.select2({
      tags: true,
      formatMatches: function() {
        return '';
      },
      dropdownCssClass: 'select2-hidden',
    });
  }
});